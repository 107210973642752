const cleanInvestorProfileData = (values) => {
    const cleanedValues = { ...values };

    // Liste des champs à vérifier avec leurs dépendances
    const fieldsToClean = [
        {
            field: 'advisorTakesEnviromentalCriteria',
            dependentField: 'choicesEnvironmentalCriteria',
        },
        {
            field: 'advisorTakeSocialCriteria',
            dependentField: 'choicesSocialCriteria',
        },
        {
            field: 'advisorTakeGovernanceCriteria',
            dependentField: 'choicesGovernanceCriteria',
        },
    ];

    // Fonction pour nettoyer un sous-objet (client ou partner)
    const cleanSubObject = (subObject) => {
        const cleanedSubObject = { ...subObject };

        fieldsToClean.forEach(({ field, dependentField }) => {
            if ('no' === cleanedSubObject[field]) {
                // Supprimer complètement le champ dépendant s'il existe
                if (dependentField in cleanedSubObject) {
                    delete cleanedSubObject[dependentField];
                }
            }
        });

        return cleanedSubObject;
    };

    // Nettoyer les sous-objets si présents
    if (cleanedValues.client) {
        cleanedValues.client = cleanSubObject(cleanedValues.client);
    }

    if (cleanedValues.partner) {
        cleanedValues.partner = cleanSubObject(cleanedValues.partner);
    }

    return cleanedValues;
};

export default cleanInvestorProfileData;
