/* eslint-disable no-await-in-loop */
import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';
import { connect } from 'react-redux';
import { flowRight } from 'lodash/fp';
import { useNavigate } from 'react-router';
import { POST } from '../../../utils/httpMethods';
import { setModal } from '../../../actions';
import { useSession } from '../../sessions';
import withTargetRecommendations from './withTargetRecommendations';

import './esigne.scss';

const manageRedirectionAndValidateRecommendation = (ripId, approvalCifBodyForm, navigate) => {
    // une fois la recommandation validée et la génération esign effectué on envoie la notification au conseiller et on redirige vers la page d'accueil
    POST(`/api/rips/${ripId}/sendAdvisorCIFNotification`, { body: approvalCifBodyForm });
    navigate('/');
};

async function getESigneStatusAndRedirect(ripId, EnvelopeId, urlRedirection, setIsGenerated, session, target, approvalCifBodyForm, navigate) {
    let reponse = '';

    while (reponse !== 'Envelope_Signature_COMPOSITION') {
        try {
            const resultat = await POST(`/api/contracts/${ripId}/eSigne/envelopes/GetStatus/${EnvelopeId}`, { body: { session, target } });

            const status = await resultat.json();
            const { statusESigne } = status;
            reponse = statusESigne;
        } catch (erreur) {
            console.error(erreur.message);
        }

        await new Promise((resolve) => setTimeout(resolve, 3000));
    }
    setIsGenerated(true);
    window.open(urlRedirection, '_blank');
    manageRedirectionAndValidateRecommendation(ripId, approvalCifBodyForm, navigate);
}

const handleRedirectToESigne = async (ripId, closeModalLoader, setIsGenerated, session, recommendations, target, enveloppe, incomes, fiscalPart, approvalCifBodyForm, navigate) => {
    try {
        const response = await POST(`/api/contracts/${ripId}/eSigne/envelopes/createOrUpdateEnvelope`, {
            body: {
                session,
                recommendations,
                target,
                enveloppe,
                incomes,
                fiscalPart,
            },
        });

        const { EnvelopeId, urlRedirection } = await response.json();

        if (EnvelopeId) {
            await getESigneStatusAndRedirect(ripId, EnvelopeId, urlRedirection, setIsGenerated, session, target, approvalCifBodyForm, navigate);
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    } finally {
        closeModalLoader();
    }
};

const ManageAndSignCIF = ({ ripId, isValuesCompleted, closeModalLoader, setModalLoader, setIsGenerated, session, recommendations, target, enveloppe, incomes, fiscalPart, approvalCifBodyForm }) => {
    const navigate = useNavigate();

    return (

        <button
            className="button-esigne"
            href="#"
            onClick={isValuesCompleted ?
                async (e) => {
                    e.preventDefault();
                    handleRedirectToESigne(
                        ripId,
                        closeModalLoader,
                        setIsGenerated,
                        session,
                        recommendations,
                        target,
                        enveloppe,
                        incomes,
                        fiscalPart,
                        approvalCifBodyForm,
                        navigate,
                    );
                    setModalLoader();
                }
                :
                null}
            type="submit"
        >
            G&eacute;n&eacute;rer et v&eacute;rifier
        </button>
    );
};
ManageAndSignCIF.propTypes = {
    ripId: PropTypes.string,
    isValuesCompleted: PropTypes.bool,
    closeModalLoader: PropTypes.func,
    setModalLoader: PropTypes.func,
    setIsGenerated: PropTypes.string,
    session: PropTypes.shape({}),
    recommendations: PropTypes.shape({}),
    target: PropTypes.string,
    enveloppe: PropTypes.shape({}),
    incomes: PropTypes.shape({}),
    fiscalPart: PropTypes.string,
    approvalCifBodyForm: PropTypes.shape({}),
};

const ESigne = ({ rip, isValuesCompleted, closeModalLoader, setModalLoader, setIsGenerated, recommendations, enveloppe, target, incomes, fiscalPart, approvalCifBodyForm }) => {
    const session = useSession();

    const { id } = rip;

    return (
        <form>
            <div className="esigne" style={{ margin: '3rem 5rem 4rem 3rem' }}>
                <span style={{ marginBottom: '1em' }}>
                    <ManageAndSignCIF
                        ripId={id}
                        setModalLoader={setModalLoader}
                        closeModalLoader={closeModalLoader}
                        isValuesCompleted={isValuesCompleted}
                        setIsGenerated={setIsGenerated}
                        recommendations={recommendations}
                        enveloppe={enveloppe}
                        target={target}
                        session={session}
                        incomes={incomes}
                        fiscalPart={fiscalPart}
                        approvalCifBodyForm={approvalCifBodyForm}
                    /> les donn&eacute;es du document de conseil CIF sur eSigne
                </span>
            </div>
        </form>
    );
};
ESigne.propTypes = {
    rip: PropTypes.shape({
        id: PropTypes.string,
    }),
    setModalLoader: PropTypes.func,
    closeModalLoader: PropTypes.func,
    isValuesCompleted: PropTypes.bool,
    setIsGenerated: PropTypes.func,
    recommendations: PropTypes.shape({}),
    enveloppe: PropTypes.shape({}),
    target: PropTypes.string,
    incomes: PropTypes.shape({}),
    fiscalPart: PropTypes.string,
    approvalCifBodyForm: PropTypes.shape({}),

};

export default flowRight([
    withTargetRecommendations,
    connect(null, (dispatch) => ({
        closeModalLoader: () => dispatch(setModal(null)),
        setModalLoader: () => {
            dispatch(setModal(() => <Spinner eSigne />, false));
        },
    })),
])(ESigne);
